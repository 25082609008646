import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	public get goBackCustomAction(): Function {
		return this._goBackCustomAction;
	}
	public set goBackCustomAction(action: Function) {
		this._goBackCustomAction = action;
	}

	private _history: string[] = [];
	private _goBackCustomAction: Function;

	constructor(
		private _router: Router,
		private _location: Location
	) {
		this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.goBackCustomAction = null;
			const newUrl = event.urlAfterRedirects;
			const lastIndex = this._history.length - 1;
			const lastRoute = this._history[lastIndex] || '';
			if (
				newUrl.includes('build-your-network') &&
				newUrl !== '/workspace/dashboard/build-your-network' &&
				lastRoute === '/workspace/dashboard/build-your-network'
			) {
				this._history.pop();
			}
			this._history.push(newUrl);
		});
	}

	public goBack(): void {
		if (this.goBackCustomAction) {
			this.goBackCustomAction();
			this.goBackCustomAction = null;
		} else if (this._history.length > 1) {
			this._history.pop();
			const previousUrl = this._history.pop();
			if (previousUrl) {
				this._router.navigateByUrl(previousUrl);
			}
		}
	}

	public changeUrl(url: string): void {
		this._location.replaceState(url);
		this._history.push(url);
	}

	public isCurrentPath(path: string): boolean {
		return !!this._history.length && this._history[this._history.length - 1].includes(path);
	}
}
